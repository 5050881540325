body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.paymentPage__img {
  width: 100%;
}
.paymentPage {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  max-width: 770px;
  width: 100%;
}
.payment__container {
  min-height: 100vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialogBlock__title {
  font-weight: 500!important;
}
.paymentPagePrice {
  margin: 52px 0 44px 0;
  font-size: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
}
.paymentPageTitle {
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  font-family: inherit;
}
.paymentPage__cashback {
  color: #fff;
  font-weight: 400;
  background-color: #038B4A;
  border-radius: 35px;
  padding: 4px 16px;
  position: absolute;
  top: 10px;
  right: 20px;
}
.paymentPage__btn:hover {
  transition: .4s;
  background-color: #1A1A1A!important;
}
.paymentPageItem {
  position: relative;
  padding: 40px;
  border-radius: 14px;
  width: 100%;
  gap: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: solid 1px #686868;
}
.paymentPage__block {
  display: flex;
  margin-bottom: 200px;
  gap: 2em;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (max-width: 600px) {
  .paymentPage__block {
    flex-wrap: wrap;
    margin-bottom: 0;
  }
}

@media (max-width: 460px) {
  .dialogBlock__actions {
    padding: 0 25px 20px 25px!important;
  }
  .dialogBlock__title {
    padding: 40px 25px 20px 25px!important;
  }
}

@media (max-width: 420px) {
  .dialogBlock__actions {
    padding: 0 15px 20px 15px!important;
  }
  .dialogBlock__btnDecline, .dialogBlock__btnAccess, .dialogBlock__form {
    width: 100%!important;
  }
  .dialogBlock__title {
    font-size: 20px!important;
    padding: 40px 15px 20px 15px!important;
  }
}
@media (max-width: 340px) {
  .dialogBlock__actions {
    flex-direction: column;
  }
}